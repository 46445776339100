import { isValidField } from './helper-function/isValidField';

export function initCreateAccountEmail() {
    // VARS
    const form = document.querySelector('[data-create-account-form]');
    const checkbox = document.querySelector('[data-create-account-checkbox]');
    const inputEmail = document.querySelector('[data-create-account-email]');
    const selectorsErrorMessage = {
        email: '[data-error-email]',
        checkbox: '[data-error-checkbox]',
    };

    if (!form) return;

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    // HANDLERS
    function handleOnSubmit(event) {
        event.preventDefault();
        validateForm();
    }

    // METHODS & FUNCTIONS
    function validateForm() {
        let isValid = true;
        let selectorErrorMessageElems = [];
        const regExpEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!isValidField(inputEmail.value, regExpEmail)) {
            isValid = false;
            selectorErrorMessageElems.push(selectorsErrorMessage.email);
        }

        if (!checkbox.checked) {
            isValid = false;
            selectorErrorMessageElems.push(selectorsErrorMessage.checkbox);
        }

        hideAllErrorMessage();

        isValid ? form.submit() : showErrorMessage(selectorErrorMessageElems);
    }

    function showErrorMessage(selectors) {
        selectors.forEach((selector) => {
            document.querySelector(selector).classList.add('is-visible');
        });
    }

    function hideAllErrorMessage() {
        Object.values(selectorsErrorMessage).forEach((selector) => {
            document.querySelector(selector).classList.remove('is-visible');
        });
    }
}
