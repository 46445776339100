import Swiper from 'swiper/bundle';

export const initTestimonialSlider = () => {
    // VARS
    let init = false;
    let swiper;

    // INITS
    swiperCard();

    // LISTENERS
    window.addEventListener('resize', swiperCard);

    // FUNCTIONS
    function swiperCard() {
        if (window.innerWidth <= 768) {
            if (!init) {
                init = true;
                swiper = new Swiper('[data-testimonial-swiper]', {
                    direction: 'horizontal',
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 32,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                });
            }
        } else if (init) {
            swiper.destroy();
            init = false;
        }
    }
};
