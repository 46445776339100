export function InitMobileMenu() {
    // VARS
    const $this = this;
    const buttonOpen = document.querySelector('[data-open-mobile-menu]');
    const buttonClose = document.querySelector('[data-close-mobile-menu]');
    const mobileMenu = document.querySelector('[data-mobile-menu]');

    if (!buttonOpen || !buttonClose || !mobileMenu) return;

    // LISTENERS
    buttonOpen.addEventListener('click', handleOnClickOpenMenu, false);
    buttonClose.addEventListener('click', handleOnClickCloseMenu, false);

    // HANDLERS
    function handleOnClickOpenMenu() {
        $this.toggleMenu();
    }

    function handleOnClickCloseMenu() {
        $this.closeMenu();
        enableScroll();
    }

    // FUNCTIONS & METHODS
    $this.toggleMenu = () => {
        if (document.body.classList.contains('is-open-menu')) {
            $this.closeMenu();
            enableScroll();
        } else {
            $this.openMenu();
            disableScroll();
        }
    };

    $this.openMenu = () => {
        document.body.classList.add('is-open-menu');
    };

    $this.closeMenu = () => {
        document.body.classList.remove('is-open-menu');
    };
}
