export const initTabs = () => {
    const tabNavigations = document.querySelectorAll('[data-tabs-navigation]');
    if (!tabNavigations.length) return;
    const activeClassNavigation = 'tabs__navigation-item--active';
    const activeClassContent = 'tabs__content--active';

    // LISTENERS
    tabNavigations.forEach((tabNavigationButton) => {
        tabNavigationButton.addEventListener('click', handleOnClickNavigation, false);
    });

    // HANDLERS
    function handleOnClickNavigation() {
        toggleTabs(this);
    }

    // FUNCTIONS
    function toggleTabs(tabNavigationButton) {
        const index = tabNavigationButton.dataset.tabsNavigation;

        closeAllTabs(tabNavigationButton);
        setActiveNavigationButton(tabNavigationButton);
        setActiveContents(index);
    }

    function setActiveNavigationButton(button) {
        button.classList.add(activeClassNavigation);
    }

    function setActiveContents(index) {
        const activeContent = document.querySelector(`[data-tabs-content="${index}"]`);
        activeContent.classList.add(activeClassContent);
    }

    function closeAllTabs(button) {
        const tabs = button.closest('[data-tabs]');
        const navigations = tabs.querySelectorAll('[data-tabs-navigation]');
        const contents = tabs.querySelectorAll('[data-tabs-content]');

        navigations.forEach((navigationButton) => {
            navigationButton.classList.remove(activeClassNavigation);
        });

        contents.forEach((content) => {
            content.classList.remove(activeClassContent);
        });
    }
};
